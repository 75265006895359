<template>
  <div class="home">
    <page-title />
    <div class="home-box">
      <div class="main">
        <div class="avatar">
          <!-- <img
          src="https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epQBpzxKDFqHNNDj5iaYpTxP6IhAGenX4GB7ghXbeT5pict2VpiaPc1Ajl7rMTdUBPxbACdMae71PicmQ/132"
          alt=""
        /> -->
          <img v-lazy="user.headImgUrl" alt="avatar" />
          <!-- <div class="user-type">
            &nbsp; &nbsp;
            <span>{{ user.levelName }}</span>
          </div> -->
        </div>
        <div class="main-box">
          <div class="main-content">
            <div class="main-content-item" @click="onClickLeft">
              <div class="main-content-item-number">
                {{ user.allScore }}
                <!-- {{ user.allScore || 0 }} -->
              </div>
              <div class="main-content-item-title">抽奖次数</div>
            </div>
            <div class="main-content-item" @click="onClickRight">
              <div class="main-content-item-number">
                {{ user.nowScore }}
              </div>
              <div class="main-content-item-title">今日剩余抽奖次数</div>
            </div>
          </div>
        </div>
      </div>
      <div class="action">
        <div class="action-item" @click="$router.push('/home/rank')">
          <img :src="require('@/assets/icons/rank.png')" alt="" />
          <span>地区排行榜</span>
        </div>
        <div class="action-item" @click="$router.push('/home/rule')">
          <img :src="require('@/assets/icons/rule.png')" alt="" />
          <span>竞赛规则</span>
        </div>
      </div>
      <img
        class="button"
        :src="require('@/assets/button2.png')"
        alt=""
        @click="onStartGame"
      />
      <router-view />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { Toast } from 'vant'

export default {
  name: 'Home',
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.$store.dispatch('GetInfo').then(response => {
      console.log('response=>', response)
      //  || !response.data.userType
      if (!response.data.street) {
        this.$router.push('/home/form')
      }
    })
  },
  methods: {
    onClickLeft() {
      this.$router.push('/home/record')
    },
    onClickRight() {
      if (this.user.nowScore > 0) {
        this.$router.push('/lucky')
      } else {
        Toast.fail('今天抽奖次数已经用完，请明天再来')
      }
    },
    onStartGame() {
      if (
        moment().isBefore(moment('2024-04-25 00:00:00', 'YYYY-MM-DD HH:mm:ss'))
      ) {
        Toast.fail('活动暂未开始')
      } else if (
        moment().isAfter(moment('2024-05-01 23:59:59', 'YYYY-MM-DD HH:mm:ss'))
      ) {
        Toast.fail('活动已经结束')
      } else {
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中'
        })
        this.$http({
          url: '/xt-exam/etQuestion/getUserSurplus'
        })
          .then(response => {
            console.log(response)
            if (response.code == 200) {
              toast.clear()
              if (!response.data) {
                //  this.$router.push('/home/video')
                this.$store.commit('UPDATE_GAME_STATUS', true)
                this.$router.push('/count-down')
              } else {
                Toast.fail('今天的答题次数已用完')
              }
            }
          })
          .catch(() => {
            toast.clear()
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.home-box {
  position: relative;
  min-height: 100%;
  padding: 0 6.4vw;
  padding-top: 12vw;
  flex: 1;
}
.main {
  position: relative;
  width: 82vw;
  height: 68vw;
  background: #f4f4f5;
  border-radius: 20px;
  margin: 0 auto;
  padding: 3vw;
  .avatar {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -12vw;
    margin-left: -12vw;
    img {
      display: block;
      width: 24vw;
      height: 24vw;
      border: 4px solid rgba(255, 255, 255, 0.44);
      box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.35);
      border-radius: 50%;
    }
    .user-type {
      position: relative;
      margin-top: -4px;
      width: 24vw;
      font-family: PingFang SC;
      font-weight: bold;
      color: #fefefe;
      background: transparent;
      padding: 1vw;
      font-size: 1.12rem;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        background: linear-gradient(0deg, #000a4e 0%, #0438ff 100%);
        // z-index: -1;
        transform: skewX(-10deg);
        border-radius: 8px;
      }
      > span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        padding: 1vw;
      }
    }
  }
  .main-box {
    height: 100%;
    width: 100%;
    padding: 4px;
    background-image: linear-gradient(
      0deg,
      #5b8f02 1%,
      #9fdd15 35%,
      transparent 90%
    );
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .main-content {
    height: 100%;
    width: 100%;
    background-color: #f4f4f5;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    .main-content-item {
      width: 50%;
      height: 26vw;
      margin-top: 15vh;
      color: #010e4f;
      position: relative;
      .main-content-item-number {
        line-height: 18vw;
        font-size: 3.42rem;
        font-family: PingFang SC;
        font-weight: 800;
        vertical-align: top;
      }
      .main-content-item-title {
        font-size: 1.04rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }

    .main-content-item:first-child::after {
      content: '';
      position: absolute;
      height: 20vw;
      width: 1px;
      top: 3vw;
      right: 0;
      background: #010e4f;
    }
  }
}
.action {
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  .action-item {
    width: 50%;
    text-align: center;
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #f4f4f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 1.29rem;
      height: 1.29rem;
      margin-right: 0.46rem;
    }
  }
}
.button {
  margin-top: 6vh;
  width: 56vw;
  cursor: pointer;
}
</style>
